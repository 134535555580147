<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div class="p-2">
            <TitleButton
                :showBtn="false"
                :showAddNew="false"
                title="Export Datasheet"
            />

            <div class="row match-height mt-1">
                <div class="col-12">
                    <v-select
                        v-model="dataType"
                        :options="dataTypes"
                        label="name"
                        :reduce="name => name.value"
                        placeholder="Select Datasheet"
                    />
                </div>
                <div class="col-12 mt-2">
                    <DateQuerySetter
                        @onClickGo="exportDataSheet"
                        btn-title="Export"
                    />
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import Loader from '@/components/atom/LoaderComponent'
import {inject, ref, computed} from 'vue'
import {useRoute} from "vue-router";

const loading = ref(false);
const dataType = ref(null);
const showError = inject('showError');
const routes = useRoute();
const token = localStorage.getItem('token');
const dataTypes = [
    {
        name: "Chart of Accounts",
        value: "account_heads"
    },
    {
        name: "General Ledger",
        value: "ledger_generals"
    }
];

const start = computed( () => {
    return routes.query.start
})
const end = computed( () => {
    return routes.query.end
})
const companyId = computed( () => {
    return routes.params.companyId
})

const exportDataSheet = () => {
    if(dataType.value === null){
        showError('Please select datasheet to export');
        return
    }
    let url = `${process.env.VUE_APP_BASE_URL}/export/datasheet/${dataType.value}?company_id=${companyId.value}`
              + `&start_date=${start.value}&end_date=${end.value}&_token=${token}`;
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.click();
}

</script>